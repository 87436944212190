import { createPortal } from 'react-dom';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import { forwardRef } from 'react';
import classes from './modal.module.scss';

interface ModalProps {
  id: string;
  title?: string;
  primaryButtonName?: string;
  modalTitle?: any;
  modalClassName?: string;
  children?: any;
  showFooterSection?: boolean;
  showSecondaryButton?: boolean;
  showPrimaryButton?: boolean;
  secondaryButtonName?: string;
  showHeader?: boolean;
  headerClass?: string;
  titleClass?: string;
  footerClass?: string;
  dialogClass?: string;
  contentClass?: string;
  headingTagName?: any;
  wrapperClass?: string;
  secondaryButtonClass?: string;
  primaryButtonClass?: string;
  modalClass?: string;
  primaryButtonVariant?: any;
  secondaryButtonVariant?: any;
  primaryOnClick?: any;
  closeModalOnClick?: any;
  modalBodyId?: string;
  closeButtonId?: string;
  centerClass?: string;
  modalBodyClass?: string;
}

const Modal = forwardRef(
  (
    {
      id,
      modalTitle,
      primaryButtonName,
      children,
      showFooterSection,
      showSecondaryButton,
      showPrimaryButton,
      secondaryButtonName,
      showHeader,
      headerClass = '',
      titleClass = '',
      footerClass,
      dialogClass,
      contentClass,
      headingTagName,
      primaryButtonClass,
      secondaryButtonClass,
      wrapperClass = '',
      modalClass = '',
      closeModalOnClick,
      modalBodyId,
      closeButtonId,
      centerClass,
      modalBodyClass = '',
    }: ModalProps,
    ref: any,
  ) => {
    const portalDiv = document.getElementById('modal')!;

    return createPortal(
      <div className={wrapperClass}>
        <div
          className={`modal fade ${modalClass}`}
          tabIndex={-1}
          id={id}
          ref={ref}
        >
          <div className={`${dialogClass} ${centerClass}`}>
            <div className={`${classes.modalContent} ${contentClass}`}>
              {showHeader && (
                <div className={`${classes.modalHeader} ${headerClass}`}>
                  {modalTitle && (
                    <Heading tagName={headingTagName} customClass={titleClass}>
                      {modalTitle}
                    </Heading>
                  )}
                  <Button
                    automationId={`close-${modalTitle?.toLowerCase().trim().replaceAll(' ', '-')}-modal`}
                    data-testid="close-modal-button"
                    type="button"
                    customClass={`${classes['closeIcon']}`}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id={closeButtonId || 'modalCloseButton'}
                    onClick={() =>
                      closeModalOnClick ? closeModalOnClick() : null
                    }
                  ></Button>
                </div>
              )}
              <div
                id={modalBodyId}
                className={`modal-body ${classes['modalBody']} ${modalBodyClass}`}
              >
                {children}
              </div>

              {showFooterSection && (
                <div className={footerClass}>
                  {showSecondaryButton && (
                    <Button
                      type="button"
                      customClass={secondaryButtonClass}
                      data-bs-dismiss="modal"
                    >
                      {secondaryButtonName}
                    </Button>
                  )}
                  {showPrimaryButton && (
                    <Button type="button" customClass={primaryButtonClass}>
                      {primaryButtonName}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>,
      portalDiv,
    );
  },
);
Modal.displayName = 'Modal';
export default Modal;
