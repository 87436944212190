import classes from './heading.module.scss';

interface HeadingProps {
  children: any;
  textFont?: 'bold' | 'normal';
  tagName: '1' | '2' | '3' | '4' | '5' | '6';
  textColor?:
    | 'primary'
    | 'secondary'
    | 'ternary'
    | 'success'
    | 'danger'
    | 'white';
  customClass?: string;
  ariaLevel?: number;
  id?: string;
  headingV2Class?: string;
}

const Heading: React.FC<HeadingProps> = (props) => {
  const {
    children,
    textFont = '',
    tagName,
    textColor = '',
    ariaLevel,
    id,
    customClass,
  } = props;

  const Tag = `h${tagName}` as keyof React.JSX.IntrinsicElements;

  return (
    <Tag
      className={`text-${textColor} fw-${textFont} ${classes['heading' + tagName]} ${customClass}`}
      aria-level={ariaLevel}
      id={id ? id : ''}
    >
      {children}
    </Tag>
  );
};

export default Heading;
